import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Careers from "./components/main/careers/Careers";
import Footer from "./components/footer";
import HeaderNav from "./components/header";
import Main from "./components/main/home";
import About from "./components/main/About";
import Privacy from "./components/privacy";
import Form from "./components/main/demoForm";
import Event from "./components/main/event";
import AllBlogs from "./components/main/blog/allBlogs";
import Community from "./components/main/community";
import SingleBlog from "./components/main/blog/singleBlog";
import CorporateSchool from "./components/main/CorporateSchool";

function App() {
  return (
    <div className="App sm:px-[40px] relative background-img">
      <HeaderNav />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Routes>
        <Route exact path="/" element={<Main />} />
        <Route exact path="/privacy" element={<Privacy />} />
        <Route exact path="/about" element={<About />} />
        <Route
          exact
          path="/corporate-training/ai"
          element={<CorporateSchool />}
        />
        <Route exact path="/event" element={<Event />} />
        <Route exact path="/community" element={<Community />} />
        <Route exact path="/demo" element={<Form />} />
        <Route exact path="/blog" element={<AllBlogs />} />
        <Route exact path="/blog/:id" element={<SingleBlog />} />
        <Route path="*" element={<Navigate reloadDocument to="/" replace />} />
        <Route path="/careers" element={<Careers />} />
      </Routes>
      <Footer />
      <style jsx="true">
        {`
          .Toastify__toast-container {
            z-index: 9999999;
          }
        `}
      </style>
    </div>
  );
}

export default App;
