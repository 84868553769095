export default function Privacy() {
  return (
    <div className="container p-5 text-white" style={{ marginTop: "120px" }}>
      <h3 className="mt-4">Privacy Policy</h3>
      <p>
        Your privacy is important to Analytics Intelligence Limited (“Analytics
        Intelligence”). ‘Analytics Intelligence Site’ refers to all websites,
        desktop and mobile apps of Analytics Intelligence This Privacy Policy
        (the “Policy”) discloses our information practices, including our
        policies regarding the types of information that is gathered and tracked
        through our websites, descriptions of how such information is used, and
        identification of the parties with whom the information may be shared.
        This Policy will be applicable to all Analytics Intelligence websites
        upon which the policy is displayed (each, an “Analytics Intelligence
        Site”). By voluntarily using the Analytics Intelligence sites, you are
        indicating your consent to this Policy and agreement to be bound by its
        terms.
      </p>
      <h2 className="mt-4 mb-4" style={{ marginTop: ".8rem" }}>
        Information Collection and Use:
      </h2>
      <h2 className="mt-5 mb-6">Personal Information</h2>
      <p>
        We will need certain personally identifiable information (“Personal
        Information”) from you. You may choose to provide us with Personal
        Information through the Analytics Intelligence sites. The purpose of
        this is to register you for our trainings. When you provide Personal
        Information in order to register on Analytics Intelligence Sites, such
        Personal Information will be used in order to provide you with access to
        our services. We may also use the Personal Information to help us
        understand who is using our products and services and to help us manage
        business development activities. If you tell us that you do not want us
        to use this Personal Information as a basis for further contact with
        you, we will respect your wishes.
      </p>
      <h2 className="mt-5 mb-6">Statistical Information</h2>
      <p>
        In addition, we sometimes collect anonymous information from visits to
        the Analytics Intelligence Sites and services such as AI Academy,
        Powerdby[AI], Data Assistant, Data Assistant, Virtual Analyst system or
        mobile app to help us provide better customer service and to make the
        Analytics Intelligence Sites as user-friendly as possible. For example,
        we keep track of the domains from which people visit and we also measure
        visitor activity on Analytics Intelligence Sites. However, when we do
        so, we do so in ways that keep the information anonymous. Analytics
        Intelligence may use this data to analyze trends and statistics and to
        help us provide better customer service. None of the information
        described in this paragraph is reviewed at an individual level.{" "}
      </p>
      <h2 className="mt-5 mb-6">Links to Third Party Sites</h2>
      <p>
        The Analytics Intelligence Sites may contain links to other Web sites.
        Analytics Intelligence is not responsible for the privacy practices or
        the content of such Web sites. The links from an Analytics Intelligence
        Site do not imply that Analytics Intelligence endorses or has reviewed
        the third party Web sites. We suggest contacting the operators of those
        Web sites directly for information on their privacy policies.
      </p>
      <h2 className="mt-5 mb-6">
        Our Disclosure of Your Personal Information and Other Information:
      </h2>
      <p>
        Analytics Intelligence does not disclose or sell your Personal
        Information. We consider this information to be a vital part of our
        relationship with you. There are, however, certain circumstances in
        which we may share your Personal Information with certain third parties
        without further notice to you, as set forth below:
      </p>
      <h4 className="mt-5 mb-6">Business Transfers:</h4>
      <p>
        As we develop our business, we might sell or buy businesses or assets.
        In the event of a corporate sale, merger, reorganization, dissolution or
        similar event, Personal Information may be part of the transferred
        assets.
      </p>
      <h4 className="mt-5 mb-6">Business Transfers:</h4>
      <p>
        Related Companies: We may also share your Personal Information with our
        subsidiaries, affiliates and other related companies for purposes
        consistent with this Policy.
      </p>
      <h4 className="mt-5 mb-6">
        Agents, Consultants and Related Third Parties:
      </h4>
      <p>
        Analytics Intelligence, like many businesses, sometimes hires other
        companies to perform certain business-related functions. Examples of
        such functions include mailing information, maintaining databases and
        processing payments. When we employ another company to perform a
        function of this nature, we only provide them with the information that
        they need to perform their specific function.
      </p>
      <h4 className="mt-5 mb-6">Legal Disclaimer:</h4>
      <p>
        Analytics Intelligence reserves the right to disclose personal
        information to non-affiliated third parties for regulatory or reporting
        purposes as required by law, to protect the personal safety of users of
        a Analytics Intelligence Site or the public, to protect our rights or
        property and when Analytics Intelligence believes that disclosure is
        necessary to protect our rights and/or to comply with any judicial
        and/or other proceedings, court order, legal process served or pursuant
        to governmental, intergovernmental and/or other regulatory bodies.
        Analytics Intelligence shall not be liable for misuse or loss of
        personal information and/or otherwise on Analytics Intelligence
        website(s) that Analytics Intelligence does not have access to or
        control over. Analytics Intelligence will not be liable for unlawful or
        unauthorized use of your personal information due to misuse or
        misplacement of your passwords, negligent or malicious intervention
        and/or otherwise. n.
      </p>
      <h2 className="mt-5 mb-6">Security</h2>
      <p>
        Personal Information collected through the Analytics Intelligence Sites
        is kept in a secure database and all reasonable precautions are taken to
        secure this data. However, no Internet or e-mail transmission is ever
        fully secure or error-free. In particular, e-mail sent to or from an
        Analytics Intelligence Site may not be secure. Therefore, you should
        take special care in deciding what information you send to us via
        e-mail. Please keep this in mind when disclosing any Personal
        Information to Analytics Intelligence via the Internet
      </p>
      <h2 className="mt-5 mb-6">Changes to Our Policy</h2>
      <p>
        We may change our business and the Analytics Intelligence Sites from
        time to time. As a result, at times it may be necessary for us to make
        changes to this Policy. Analytics Intelligence reserves the right to
        update or modify this Policy at any time and from time to time without
        prior notice. Please review this policy periodically, and especially
        before you provide any Personal Information. Your continued use of the
        Analytics Intelligence Site after any changes or revisions to this
        Policy shall indicate your agreement with the terms of such revised
        Policy.
      </p>
      <h2 className="mt-5 mb-6">
        Access to Personal Information and Other Questions
      </h2>
      <p>
        Under Article 15 of the General Data Protection Regulation, you have the
        right to obtain confirmation from Analytics Intelligence relating to the
        processing of your personal data, including the right to request a copy
        of all data processed about yourself. You also have the right to contact
        us to request information about the Personal Information we have
        collected from you in order to request the correction, modification or
        deletion of such information. To make such a request and/or to pose any
        other questions regarding this Policy, please contact us via
        email: privacy@analyticsintelligence.com
      </p>
      <style jsx="true">
        {`
          .mt-5 {
            margin-top: 0.9rem;
            margin-bottom: 0.9rem;
          }
        `}
      </style>
    </div>
  );
}
