import React from "react";

const Tick = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <path
        d="M10.5001 18.8651L5.63506 14.0001L3.97839 15.6451L10.5001 22.1667L24.5001 8.16673L22.8551 6.52173L10.5001 18.8651Z"
        fill="#01ADFF"
      />
    </svg>
  );
};

export default Tick;
