import { useEffect, useState } from "react";
import { Helmet, HelmetData } from "react-helmet-async";
import Swiper, { Navigation, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { client } from "../../client";

const helmetData = new HelmetData({});
export default function Event() {
  const [events, setEvents] = useState([]);
  const [sortedEvents, setSortedEvents] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const [search, setSearch] = useState("");
  const [searchPage, setSearchPage] = useState(false);

  useEffect(() => {
    setSortedEvents(events?.sort((a, b) => b.id - a.id));
  }, [events]);
  useEffect(() => {
    new Swiper(".swiper", {
      modules: [Navigation, Pagination],
      // Optional parameters
      direction: "horizontal",
      loop: true,

      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
      },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
  }, []);

  // useEffect(()=>{
  //   fetch(`https://cms.analyticsintelligence.ai/api/events`)
  //   .then(res => res.json())
  //   .then(event => {
  //     setEvents(event.data);
  //   })
  // },[])
  useEffect(() => {
    client
      .fetch(
        `*[_type == "Event"]{
      title,
      speakers,
      date,
      flyers,
      time,
      watch_link,
      twitter,
      telegram,
      id
    }`
      )
      .then((data) => {
        setEvents(data);
      })
      .catch(console.error);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (search) {
      setSearchPage(true);
    }
    function capitalizeSentence(search) {
      const words = search.split(" ");
      const capitalizedWords = words.map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      });
      const capitalizedSentence = capitalizedWords.join(" ");
      return capitalizedSentence;
    }
    const modified = capitalizeSentence(search);
    setSearchResult(
      events.filter(
        (e) => e.title.includes(search) || e.title.includes(modified)
      )
    );
  };

  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] Events | Webinars | Conferences | Community Event</title>
        <link
          rel="canonical"
          href="https://africa.analyticsintelligence.com/event"
        />
        <meta
          name="description"
          content="One of our focuses at the Academy is to build a vibrant community of Learners, Developers and Analysts, equipping them with the necessary skills to be market-ready through our content, workshops and webinars, and events. Watch this space for upcoming events from the Academy."
        />
        <meta
          name="keywords"
          content="Academy, vibrant community, Learners, Developers, Analysts, necessary skills, market-ready, content, workshops, webinars, events, upcoming events"
        />
      </Helmet>
      <main className="flex flex-col text-white flex flex-col pb-[150px]">
        <div className="bg-header">
          <div className="container pt-[200px] w-full flex flex-col items-center gap-[40px] justify-center pb-[50px]">
            <div className=" flex flex-col gap-[20px] text-center">
              <h1 className="md:text-[55px] text-[32px] font-[400]">Events</h1>
              <p className="sm:text-[18px] text-[16px] leading-8">
                One of our focuses at the Academy is to build a vibrant
                community of Learners, Developers and Analysts, equipping them
                with the necessary skills to be market-ready through our
                content, workshops and webinars, and events. Watch this space
                for upcoming events from the Academy.
              </p>
            </div>
            <form
              className="relative lg:w-[35%] md:w-[60%] w-full mx-auto"
              onSubmit={handleSubmit}
            >
              <label className="">
                <input
                  type="text"
                  className="w-full pl-[70px] px-[20px] py-[15px] rounded-[33px] bg-[#FFFFFF] bg-opacity-10 outline-none"
                  placeholder="Search event"
                  required
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </label>
              <button className="absolute px-[20px] py-[15px] rounded-[33px] bg-[#5ACBF5] outline-none text-black right-0 font-[500]">
                Search
              </button>
              <div className="absolute left-0 px-[20px] py-[15px] top-0">
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686912196/Vector_2_stf633.png"
                  alt="icon"
                />
              </div>
            </form>
          </div>
        </div>
        {!searchPage && (
          <div className="flex flex-col gap-[45px]">
            <h2 className="font-[400] md:text-[36px] text-[25px] text-center">
              All events
            </h2>
            <div className="container flex flex-wrap gap-[30px] justify-center">
              {sortedEvents &&
                sortedEvents?.map((event) => (
                  <div
                    className="lg:w-[31.5%] md:w-[48%] w-full bg-[#242424] rounded-[10px] p-[13px]"
                    key={event?.id}
                  >
                    {event?.flyers?.length > 1 && (
                      <div className="swiper">
                        <div className="swiper-wrapper">
                          {event?.flyers?.map((f) => (
                            <div
                              className="swiper-slide max-h-[200px] min-h-[199px] overflow-hidden"
                              key={f}
                            >
                              <img src={f} alt="event" className="brand" />
                            </div>
                          ))}
                        </div>
                        <div className="swiper-pagination"></div>
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                      </div>
                    )}
                    {event?.flyers?.length === 1 &&
                      event?.flyers?.map((f) => (
                        <div
                          className="max-h-[200px] min-h-[199px]  overflow-hidden"
                          key={f}
                        >
                          <img src={f} alt="event" className="brand " />
                        </div>
                      ))}
                    <div className="flex flex-col gap-[12px] justify-between min-h-[200px] mt-[24px]">
                      <h3 className="font-[400] md:text-[22px] text-[20px]">
                        {event?.title}
                      </h3>
                      <div className="flex flex-col gap-[20px]">
                        <div className="flex justify-between">
                          <div className="flex gap-[12px]">
                            <img
                              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686913833/uil_calender_nfo2jj.png"
                              alt=""
                            />
                            <span className="text-[16px]">
                              {new Date(event?.date).toLocaleDateString(
                                "en-US",
                                {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                }
                              )}
                            </span>
                          </div>
                          <div className="flex gap-[12px]">
                            <img
                              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686913829/mingcute_time-line_rfyeql.png"
                              alt=""
                            />
                            <span className="text-[16px]">{event?.time}</span>
                          </div>
                        </div>
                        <div className="flex sm:flex-row flex-col justify-between sm:items-center items-start gap-[20px]">
                          <div className="flex gap-[10px]">
                            {event?.speakers?.map((speaker) => (
                              <img
                                className="object-cover rounded-[50%] h-[44px] w-[44px] border border-[3px] border-[#F3F3F3]"
                                src={speaker}
                                alt="avatar"
                                key={speaker}
                              />
                            ))}
                          </div>
                          <a
                            href={event?.watch_link}
                            target="_blank"
                            rel="noreferrer"
                            className="px-[20px] py-[15px] rounded-[33px] bg-[#5ACBF5] outline-none text-black right-0 font-[500]"
                          >
                            {event?.twitter
                              ? "Listen In"
                              : event?.telegram
                              ? "Join now"
                              : "Watch now"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
        {searchPage && (
          <div className="bg-africa">
            <div className="flex justify-center">
              <button
                className="px-[20px] py-[15px] rounded-[33px] bg-[#5ACBF5] outline-none text-black right-0 font-[500] mb-[40px]"
                onClick={() => {
                  setSearchPage(false);
                  setSearch("");
                  // searchResult([])
                }}
              >
                Back
              </button>
            </div>
            <div className="container flex flex-wrap gap-[30px] justify-center">
              {searchResult &&
                searchResult.map((event) => (
                  <div
                    className="lg:w-[31.5%] md:w-[48%] w-full bg-[#242424] rounded-[10px] p-[13px]"
                    key={event?.id}
                  >
                    {event.flyers.length > 1 && (
                      <div className="swiper">
                        <div className="swiper-wrapper">
                          {event?.flyers?.map((f) => (
                            <div
                              className="swiper-slide  max-h-[200px] min-h-[199px] overflow-hidden"
                              key={f}
                            >
                              <img src={f} alt="event" className="brand" />
                            </div>
                          ))}
                        </div>
                        <div className="swiper-pagination"></div>
                        <div className="swiper-button-prev"></div>
                        <div className="swiper-button-next"></div>
                      </div>
                    )}
                    {event?.flyers?.length === 1 &&
                      event?.flyers?.map((f) => (
                        <div
                          className="max-h-[200px] min-h-[199px] overflow-hidden"
                          key={f}
                        >
                          <img src={f} alt="event" className="brand " />
                        </div>
                      ))}
                    <div className="flex flex-col gap-[12px] justify-between min-h-[200px] mt-[24px]">
                      <h3 className="font-[400] md:text-[22px] text-[20px]">
                        {event?.title}
                      </h3>
                      <div className="flex flex-col gap-[20px]">
                        <div className="flex justify-between">
                          <div className="flex gap-[12px]">
                            <img
                              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686913833/uil_calender_nfo2jj.png"
                              alt=""
                            />
                            <span className="text-[16px]">
                              {new Intl.DateTimeFormat("en-GB", {
                                dateStyle: "long",
                                weekday: undefined,
                              }).format(new Date(event?.date))}
                            </span>
                          </div>
                          <div className="flex gap-[12px]">
                            <img
                              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686913829/mingcute_time-line_rfyeql.png"
                              alt=""
                            />
                            <span className="text-[16px]">{event?.time}</span>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="flex gap-[10px]">
                            {event?.speakers?.map((speaker) => (
                              <img
                                className="object-cover rounded-[50%] h-[44px] w-[44px] border border-[3px] border-[#F3F3F3]"
                                src={speaker}
                                alt="avatar"
                                key={speaker}
                              />
                            ))}
                          </div>
                          <a
                            href={event?.watch_link}
                            target="_blank"
                            rel="noreferrer"
                            className="px-[20px] py-[15px] rounded-[33px] bg-[#5ACBF5] outline-none text-black right-0 font-[500]"
                          >
                            {event?.twitter
                              ? "Listen In"
                              : event?.telegram
                              ? "Join now"
                              : "Watch now"}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {searchResult.length === 0 && (
                <div className="text-[30px]"> No Match Found </div>
              )}
            </div>
          </div>
        )}
      </main>
      <style jsx="true">
        {`
          .swiper-button-next,
          .swiper-button-prev {
            color: #000;
          }
          .swiper-pagination-bullet-active {
            background-color: #000;
          }
          .gif {
            object-fit: cover;
            height: 650px;
            width: 1004px;
          }
          .bgg {
            background: linear-gradient(
                to bottom,
                rgba(90, 203, 245, 0.1),
                rgba(90, 203, 245, 0.1)
              ),
              url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1686660448/Line_2_yjmzxh.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
          }
          .bg-africa {
            background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853939/PngItem_5110390_1_xq3uvu.png");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
          }
          // .bg-header{
          //   background: url('https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853999/Line_5_1_e9mwkz.png');
          //   background-repeat: no-repeat;
          //   background-size: 100%;
          //   // background-position: 20%;
          // }
        `}
      </style>
    </>
  );
}
