import React from "react";

const Desktop = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
    >
      <path
        d="M8.39995 4.79999C7.02083 4.79999 5.69818 5.34784 4.723 6.32303C3.74781 7.29822 3.19995 8.62086 3.19995 9.99999V34.8C3.19995 36.1791 3.74781 37.5018 4.723 38.4769C5.69818 39.4521 7.02083 40 8.39995 40H19.216V43.2016H14.4192C14.2049 43.1948 13.9914 43.2312 13.7915 43.3085C13.5916 43.3858 13.4092 43.5025 13.2552 43.6517C13.1013 43.8008 12.9788 43.9794 12.8952 44.1768C12.8116 44.3742 12.7686 44.5864 12.7686 44.8008C12.7686 45.0152 12.8116 45.2273 12.8952 45.4248C12.9788 45.6222 13.1013 45.8007 13.2552 45.9499C13.4092 46.0991 13.5916 46.2158 13.7915 46.2931C13.9914 46.3704 14.2049 46.4068 14.4192 46.4H36.8064C37.0206 46.4068 37.2341 46.3704 37.434 46.2931C37.634 46.2158 37.8163 46.0991 37.9703 45.9499C38.1242 45.8007 38.2467 45.6222 38.3303 45.4248C38.4139 45.2273 38.4569 45.0152 38.4569 44.8008C38.4569 44.5864 38.4139 44.3742 38.3303 44.1768C38.2467 43.9794 38.1242 43.8008 37.9703 43.6517C37.8163 43.5025 37.634 43.3858 37.434 43.3085C37.2341 43.2312 37.0206 43.1948 36.8064 43.2016H32.0096V40H42.8C44.1791 40 45.5017 39.4521 46.4769 38.4769C47.4521 37.5018 48 36.1791 48 34.8V9.99999C48 8.62086 47.4521 7.29822 46.4769 6.32303C45.5017 5.34784 44.1791 4.79999 42.8 4.79999H8.39995ZM28.8096 40V43.2016H22.416V40H28.8096ZM6.39995 9.99999C6.39995 8.89599 7.29595 7.99999 8.39995 7.99999H42.8C43.904 7.99999 44.8 8.89599 44.8 9.99999V34.8C44.8 35.904 43.904 36.8 42.8 36.8H8.39995C7.29595 36.8 6.39995 35.904 6.39995 34.8V9.99999Z"
        fill="#01ADFF"
      />
    </svg>
  );
};

export default Desktop;
