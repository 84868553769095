import { useForm } from "@formspree/react";
import { toast } from "react-toastify";
export default function Form() {
  const [state, handleSubmit] = useForm("xvoelkrv");
  const handleError = (error) => {
    toast.dismiss();
    toast.error(error.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const handleSuccess = (message) => {
    toast.dismiss();
    toast.success(message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  if (state.succeeded) {
    handleSuccess("Request sucessfully sent, We wil be in touch shortly");
  }

  return (
    <div className="  ">
      <form method="POST" onSubmit={handleSubmit}>
        <div className="field my-[15px]">
          <div className="control">
            <input
              className="form-input bg-white"
              required
              type="text"
              placeholder="Name"
              id="Name"
              name="Name"
            />
          </div>
        </div>
        <div className="field my-[15px] ">
          <div className="control">
            <input
              className="form-input bg-white"
              required
              type="text"
              placeholder="Company"
              id="Company"
              name="Company"
            />
          </div>
        </div>
        <div className="field my-[15px]">
          <div className="control">
            <input
              className="form-input bg-white"
              required
              type="email"
              placeholder="Email"
              id="Email"
              name="Email"
            />
          </div>
        </div>
        <div className="field my-[15px]">
          <div className="control">
            <input
              className="form-input bg-white"
              required
              type="text"
              placeholder="Enter phone number"
              id="Phone number"
              name="Phone number"
            />
          </div>
        </div>
        <div className="field my-[15px]">
          <div className="control">
            <textarea
              className="form-input bg-white"
              type="text"
              placeholder="Enter any other comment"
              id="comment"
              name="comment"
            />
          </div>
        </div>
        <button
          disabled={state.submitting}
          type="submit"
          className="w-full mx-auto mt-4 flex justify-center items-center px-[56px] py-4 bg-[#5ACBF5] text-[#191919] rounded-xl"
        >
          {state.submitting ? "Submitting" : "Empower Your Team"}
        </button>
        <p className="w-full text-center text-[rgba(255,255,255,0.70)] text-[18px]">
          Join over 100 companies and upskill your work force
        </p>
      </form>
      <style jsx="true">{`
        select {
          appearance: none;
          -webkit-appearance: none;
          -moz-appearance: none;
        }
        .field {
          display: flex;
          flex-direction: column;
          gap: 10px;
          width: 100%;
        }
        form {
          display: flex;
          flex-direction: column;
          gap: 15px;
          align-items: start;
          width: 100%;
        }
        label {
          font-size: 20px;
        }
        @media (max-width: 500px) {
          label {
            font-size: 18px;
          }
          form {
            gap: 6px;
          }
        }
      `}</style>
    </div>
  );
}
