import { Helmet, HelmetData } from "react-helmet-async";
const helmetData = new HelmetData({});

export default function About() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] Analytics Intelligence Africa | How we started out</title>
        <link
          rel="canonical"
          href="https://africa.analyticsintelligence.com/"
        />
        <meta
          name="description"
          content="The 21st-century human lives at the intersection of data and technology. At [AI] Analytics Intelligence, we believe the world would be more inclusive if everyone had simple, quicker access to insights generated via data."
        />
        <meta
          name="keywords"
          content="data and technology, inclusive, simple access, quicker access, insights, data analytics, artificial intelligence, software development company, empowering businesses, Africa, unique challenges, opportunities, African market, cutting-edge solutions, specific needs, digital transformation, trusted partner, data's potential, informed decisions, growth, sustainable development, revolutionizing, data utilization, innovative analytics, AI solutions, empowering companies, compete globally, economic development"
        />
      </Helmet>
      <div className="bg-header">
        <div className="container flex flex-col gap-[100px] pt-[200px] pb-[150px] md:px-[50px]  text-white">
          <div className="flex flex-col gap-[10px] ">
            <h1 className="md:text-[45px] text-[30px]">About us</h1>
            <p className="text-justify leading-[35px]">
              The 21st-century human lives at the intersection of data and
              technology. At [AI] Analytics Intelligence, we believe the world
              would be more inclusive if everyone had simple, quicker access to
              insights generated via data.
              <br />
              [AI] Analytics Intelligence is a data analytics and artificial
              intelligence software development company with a focus on
              empowering businesses in Africa. With a deep understanding of the
              unique challenges and opportunities in the African market, we are
              committed to delivering cutting-edge solutions tailored to your
              specific needs.
            </p>
            <img
              className="md:w-[80%] mx-auto mt-[20px]"
              src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1687423369/_MG_0286_1_kppe31.png"
              alt=""
            />
          </div>
          <div className="flex gap-[50px] md:flex-row flex-col items-center">
            <div className="flex flex-col gap-[15px] md:w-1/2">
              <h2 className="md:text-[45px] text-[30px]">Our Vision</h2>
              <div className="flex flex-col gap-[20px]">
                <p className="text-justify leading-[35px]">
                  Our vision is to drive digital transformation in Africa
                  through data analytics and AI. We aim to be the trusted
                  partner for organizations, unlocking their data's potential
                  for informed decisions, growth, and sustainable development.
                </p>
              </div>
            </div>
            <div className="md:w-1/2">
              <img
                className="w-full"
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1687423359/_MG_0302_1_cfueyj.png"
                alt=""
              />
            </div>
          </div>
          <div className="flex gap-[50px] md:flex-row-reverse flex-col items-center">
            <div className="flex flex-col gap-[15px] md:w-1/2">
              <h2 className="md:text-[45px] text-[30px]">Our Mission</h2>
              <div className="flex flex-col gap-[20px]">
                <p className="text-justify leading-[35px]">
                  Revolutionizing African businesses' data utilization through
                  innovative analytics and AI solutions. Empowering companies to
                  compete globally and drive economic development in the region.
                </p>
              </div>
            </div>
            <div className="md:w-1/2">
              <img
                className="w-full"
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1687423358/_MG_0149_1_1_c18nbw.png"
                alt=""
              />
            </div>
          </div>
          <style jsx="true">
            {`
              // .bg-header{
              //   background: url('https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853999/Line_5_1_e9mwkz.png');
              //   background-repeat: no-repeat;
              //   background-size: 100%;
              //   background-position: center;
              // }
            `}
          </style>
        </div>
      </div>
    </>
  );
}
