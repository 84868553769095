import { Helmet, HelmetData } from "react-helmet-async";
import {
  Desktop,
  Dot,
  InPerson,
  NeuralIcon,
  NeuralIcon2,
  NeuralIcon3,
  Tick,
} from "../../icons";
import Form from "./f";
const helmetData = new HelmetData({});

export default function CorporateSchool() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>[AI] Analytics Intelligence Africa | How we started out</title>
        <link
          rel="canonical"
          href="https://africa.analyticsintelligence.com/corporate-training/ai"
        />
        <meta
          name="description"
          content="The 21st-century human lives at the intersection of data and technology. At [AI] Analytics Intelligence, we believe the world would be more inclusive if everyone had simple, quicker access to insights generated via data."
        />
        <meta
          name="keywords"
          content="corporate training, c-levels training, prompt engineering, data and technology, inclusive, simple access, quicker access, insights, data analytics, artificial intelligence, software development company, empowering businesses, Africa, unique challenges, opportunities, African market, cutting-edge solutions, specific needs, digital transformation, trusted partner, data's potential, informed decisions, growth, sustainable development, revolutionizing, data utilization, innovative analytics, AI solutions, empowering companies, compete globally, economic development"
        />
      </Helmet>
      <div className="bg-corporate">
        <div className="container flex flex-col gap-[100px] pt-[120px] md:pt-[200px] pb-[75px] md:pb-[150px] md:px-[50px]  text-white">
          <div className="flex flex-col md:flex-row gap-10 md:gap-4">
            <div className="w-full md:w-1/2">
              <h1 className="w-full max-w-[654px] text-[#5ACBF5] text-[32px] md:text-[44px] font-[600] md:leading-[62px]   mb-5 md:mb-8">
                Enhance your workforce productivity with our Prompt Engineering
                Trainings
              </h1>
              {/* <h2 className=" text-[18px] md:text-[20px]">
                Unlock the Complete Power of Generative AI through Expert
                Instruction
              </h2> */}
              <div className="flex flex-col gap-4 mb-5 md:mb-8">
                <div className="flex gap-3 w-full max-w-[481px]">
                  <Tick />
                  <span className="text-[rgba(255,255,255,0.70)]">
                    We tailor our training to each employee's unique role,
                    position, and tasks, ensuring relevant and effective
                    learning.
                  </span>
                </div>
                <div className="flex gap-3 w-full max-w-[481px]">
                  <Tick />
                  <span className="text-[rgba(255,255,255,0.70)]">
                    Our expert facilitators will guide you in developing
                    high-impact skills in prompt engineering
                  </span>
                </div>
                <div className="flex gap-3 w-full max-w-[481px]">
                  <Tick />
                  <span className="text-[rgba(255,255,255,0.70)]">
                    Understand how to use generative AI ethically and
                    responsibly, minimizing risks and ensuring success.
                  </span>
                </div>
              </div>
              <a href="#contact-us">
                <button className="flex justify-center items-center py-4 px-10 rounded-[10px] bg-[#5ACBF5] text-black hover:bg-[#CCCCCC] transition-all duration-300">
                  Contact us
                </button>
              </a>
            </div>
            <div className="w-full md:w-1/2">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1713987397/heroVisuals_w1fzqg.png"
                alt=" Enhance your workforce productivity with our Prompt Engineering
                Trainings"
                width={813}
                height={597}
              />
            </div>
          </div>

          <div
            id="contact-us"
            className="flex flex-col md:flex-row gap-5 md:gap-10 justify-between items-end"
          >
            <div className="w-full md:w-1/2 max-w-[674px]">
              <h1 className="w-full text-[#5ACBF5] text-[32px] md:text-[36px] font-[600] md:leading-[62px] ">
                Schedule A Discovery Call Today
              </h1>
              <p className=" text-[rgba(255,255,255,0.95)] mt-[10px] mb-[60px]">
                Get access to bespoke GenAI training for each of your teams and
                improve productivity
              </p>
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1713987397/IT-CON_1_rpjzdy.png"
                alt="Unlock the Power of Generative AI in Your Organization"
                width={600}
                height={400}
              />
            </div>
            <div className=" w-full md:w-1/2 max-w-[692px]">
              <Form />
            </div>
          </div>
          <div className="my-8 md:my-[100px] flex flex-col md:flex-row justify-between items-center gap-10 p-6 bg-[#1E1E1E]   rounded-[20px]">
            <div className="flex flex-col gap-5 w-full max-w-[405px]">
              <NeuralIcon />
              <p className=" text-[rgba(255,255,255,0.95)]">
                92% of surveyed employers believe their IT departments will be
                the biggest beneficiary of AI, and they also believe other
                departments will derive significant value from it
              </p>
            </div>
            <div className="hidden md:block w-[1px] h-[84px] bg-[rgba(255,255,255,0.30)] " />
            <div className="flex flex-col gap-5 w-full max-w-[405px]">
              <NeuralIcon2 />
              <p className=" text-[rgba(255,255,255,0.95)]">
                90% of executives and managers say their organizations either
                face skill gaps already or expect that these numbers would
                continue to rise within the next five years and beyond
              </p>
            </div>
            <div className="hidden md:block w-[1px] h-[84px] bg-[rgba(255,255,255,0.30)] " />
            <div className="flex flex-col gap-5 w-full max-w-[405px] self-start">
              <NeuralIcon3 />
              <p className=" text-[rgba(255,255,255,0.95)]">
                $13T estimated contribution to the global economy by 2030 from
                GenAI
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-10 md:gap-[55px] justify-center items-center  max-w-[1252px] mx-auto">
            <h1 className="w-full  text-center text-[#5ACBF5] text-[32px] md:text-[40px] font-[600] md:leading-[62px]   ">
              Unlock the Power of Generative AI for C-Level Executives
            </h1>
            <h2 className="text-[rgba(255,255,255,0.70)] text-center">
              As a business leader, you need to stay ahead of the curve in
              harnessing the potential of Generative AI to drive corporate
              strategy, innovation, and growth. Prompt Engineering is the key to
              unlocking the full benefits of GenAI, and we've got the expertise
              to guide you every step of the way.
            </h2>
            <div className="flex flex-col md:flex-row w-full max-w-[1170px] justify-between gap-10 md:gap-5">
              <div className="rounded-[10px] bg-[#1E1E1E]  flex flex-col items-center w-full max-w-[536px]  rounded-b-xl rounded-r-3xl rounded-l-3xl ">
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1713987397/leadwithConfidence_yqjbxz.png"
                  alt="Lead with Confidence"
                  width={536}
                  height={338}
                />
                <div className="p-6">
                  <h1 className="text-[#5ACBF5]  font-[600] text-[24px] md:text-[32px] mb-3">
                    Lead with Confidence
                  </h1>
                  <ul className="list-disc ml-5">
                    <li className="text-[rgba(255,255,255,0.70)] leading-[200%]">
                      Develop a comprehensive GenAI corporate strategy that
                      drives business outcomes
                    </li>
                    <li className="text-[rgba(255,255,255,0.70)] leading-[200%]">
                      Accelerate decision-making with data-driven insights
                    </li>
                    <li className="text-[rgba(255,255,255,0.70)] leading-[200%]">
                      Save time and res by automating routine tasks
                    </li>
                  </ul>
                </div>
              </div>
              <div className="rounded-[10px] bg-[#1E1E1E]  flex flex-col items-center w-full max-w-[536px]  rounded-b-xl rounded-r-3xl rounded-l-3xl ">
                <img
                  src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1713987396/ElevateYourLeadership_wyyceo.png"
                  alt="Elevate Your Leadership"
                  width={536}
                  height={338}
                />
                <div className="p-6">
                  <h1 className="text-[#5ACBF5]  font-[600] text-[24px] md:text-[32px] mb-3">
                    Elevate Your Leadership
                  </h1>
                  <ul className="list-disc ml-5">
                    <li className="text-[rgba(255,255,255,0.70)] leading-[200%]">
                      Drive business innovation and stay ahead of the
                      competition
                    </li>
                    <li className="text-[rgba(255,255,255,0.70)] leading-[200%]">
                      Make informed, data-driven decisions with confidence
                    </li>
                    <li className="text-[rgba(255,255,255,0.70)] leading-[200%]">
                      Unlock new revenue streams and growth opportunities
                    </li>
                    <li className="text-[rgba(255,255,255,0.70)] leading-[200%]">
                      Lead your organization to success in the GenAI era
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <a href="#contact-us">
              <button className="flex justify-center items-center py-4 px-10 rounded-[10px] bg-[#5ACBF5] text-black hover:bg-[#CCCCCC] transition-all duration-300">
                Contact us
              </button>
            </a>
          </div>
          <div className="flex flex-col md:flex-row-reverse gap-10 justify-between items-center md:gap-4 my-8 ">
            <div className="w-full md:w-1/2 max-w-[654px]">
              <h1 className="w-full  text-[#5ACBF5] text-[32px] md:text-[40px] font-[600] md:leading-[62px]   mb-5 md:mb-8">
                Unlock the Power of Generative AI in Your Organization
              </h1>
              <h2 className=" text-[18px] md:text-[22px]">
                Discover how [AI] can help you unlock innovation and
                productivity today. Our expert-led programs empower you to
              </h2>
              <div className="flex flex-col gap-4 mt-2">
                <div className="flex gap-[6px] w-full max-w-[481px]">
                  <Dot />
                  <span className="text-[rgba(255,255,255,0.70)] -mt-1">
                    Develop high-impact skills to drive business growth and
                    competitiveness
                  </span>
                </div>
                <div className="flex gap-[6px] w-full max-w-[481px]">
                  <Dot />
                  <span className="text-[rgba(255,255,255,0.70)] -mt-1">
                    Supercharge cross-functional productivity with efficient
                    workflows and automation.
                  </span>
                </div>
                <div className="flex  gap-[6px] w-full max-w-[481px]">
                  <Dot />
                  <span className="text-[rgba(255,255,255,0.70)] -mt-1">
                    Foster a culture of responsible GenAI adoption and ethical
                    use.
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 max-w-[546px">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1713987397/ai-cloud-concept-with-robot-arm_1_rez5gl.png"
                alt="Unlock the Power of Generative AI in Your Organization"
                width={546}
                height={384}
              />
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-10 justify-between items-center md:gap-4 my-8 ">
            <div className="w-full md:w-1/2 max-w-[654px]">
              <h1 className="w-full  text-[#5ACBF5] text-[32px] md:text-[40px] font-[600] md:leading-[62px]   mb-5 md:mb-8">
                Breakthrough to New Insights with Prompt Engineering for
                Analytics
              </h1>
              <h2 className=" text-[18px] md:text-[20px]">
                Discover how Prompt Engineering can help you unlock innovation
                and productivity in your analytics team today. Our expert-led
                programs empower you to:
              </h2>
              <div className="flex flex-col gap-4 mt-4">
                <div className="flex gap-[6px] w-full max-w-[481px]">
                  <Dot />
                  <span className="text-[rgba(255,255,255,0.70)] -mt-1">
                    Amplify your Data Analytics skills
                  </span>
                </div>
                <div className="flex gap-[6px] w-full max-w-[481px]">
                  <Dot />
                  <span className="text-[rgba(255,255,255,0.70)] -mt-1">
                    Boost analytics productivity with efficient workflows and
                    automation
                  </span>
                </div>
                <div className="flex  gap-[6px] w-full max-w-[481px]">
                  <Dot />
                  <span className="text-[rgba(255,255,255,0.70)] -mt-1">
                    Streamline data processing and insights generation with
                    AI-driven prompts
                  </span>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/2 max-w-[546px]">
              <img
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1714409744/new-image_y38luf.png"
                alt="Unlock the Power of Generative AI in Your Organization"
                width={546}
                height={384}
              />
            </div>
          </div>
          <div className="flex flex-col gap-10 md:gap-[64px] justify-center items-center my-8  max-w-[1252px] mx-auto">
            <h1 className="w-full  text-center text-[#5ACBF5] text-[32px] md:text-[40px] font-[600] md:leading-[62px]   ">
              Flexible training Options
            </h1>
            <div className="flex flex-col md:flex-row w-full max-w-[1170px] justify-between gap-5 md:gap-[70px]">
              <div className="rounded-[12px] bg-[#1E1E1E]  flex flex-col p-[24px] md:p-[36px] max-w-[388px]">
                <Desktop />
                <h1 className="text-[rgba(255,255,255,0.90)]  font-[600] text-[18px] md:text-[24px] my-3">
                  Virtual-Instructor Led Training
                </h1>
                <p className="text-[rgba(255,255,255,0.70)] leading-[200%]">
                  Join live, online sessions from anywhere in the world, with
                  the same expert instruction and interaction
                </p>
              </div>
              <div className="rounded-[12px] bg-[#1E1E1E]  flex flex-col p-[24px] md:p-[36px] max-w-[388px]">
                <InPerson />
                <h1 className="text-[rgba(255,255,255,0.90)]  font-[600] text-[18px] md:text-[24px] my-3">
                  In-Person Training
                </h1>
                <p className="text-[rgba(255,255,255,0.70)] leading-[200%]">
                  Engage in interactive, physical training sessions with our
                  expert instructors
                </p>
              </div>
            </div>
            <a href="#contact-us">
              <button className="flex justify-center items-center py-4 px-10 rounded-[10px] bg-[#5ACBF5] text-black hover:bg-[#CCCCCC] transition-all duration-300">
                Get Started
              </button>
            </a>
          </div>

          <style jsx="true">
            {`
              // .bg-header{
              //   background: url('https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853999/Line_5_1_e9mwkz.png');
              //   background-repeat: no-repeat;
              //   background-size: 100%;
              //   background-position: center;
              // }
            `}
          </style>
        </div>
      </div>
    </>
  );
}
