import { Helmet, HelmetData } from "react-helmet-async";

const helmetData = new HelmetData({});

export default function Community() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Communities | Analytics Community | Developers Community
        </title>
        <link
          rel="canonical"
          href="https://africa.analyticsintelligence.com/community"
        />
        <meta
          name="description"
          content="Collaborate with friends and colleagues, share ideas with like minded individuals and learn from industry experts"
        />
        <meta
          name="keywords"
          content="collaborate, friends, colleagues, share ideas, like-minded individuals, learn, industry experts"
        />
      </Helmet>
      <main className="flex flex-col text-white flex flex-col gap-[50px] pb-[150px]">
        <div className="bg-header">
          <div className="bg-community container mt-[200px] w-full flex flex-col gap-[40px] items-center pb-[50px] md:pb-[150px] lg:pb-[350px] ">
            <div className=" flex flex-col gap-[20px] text-center md:w-[60%] w-full mx-auto">
              <h1 className="md:text-[55px] text-[32px] font-[400]">
                Community
              </h1>
              <p className="sm:text-[18px] text-[16px] leading-8">
                Collaborate with friends and colleagues, share ideas with like
                minded individuals and learn from industry experts
              </p>
            </div>
            <a
              href="https://t.me/+OFUW6YutOewyNzk0"
              target="_blank"
              className="bg-[#5ACBF5] outline-none px-[20px] py-[15px] rounded-[33px] text-black font-[500] relative lg:w-[20%] md:w-[40%] sm:w-[60%] mx-auto text-center"
              rel="noreferrer"
            >
              Join Community
            </a>
          </div>
        </div>
        <div className="container flex flex-col gap-[80px]">
          <h2 className="text-center font-[600] md:text-[32px] text-[25px]">
            The [AI] Community is a circle of like-minded people interested in
            Data Analytics and Artificial Intelligence
          </h2>
          <div className="flex md:flex-row flex-col md:gap-[80px] gap-[30px] items-center">
            <div className="md:w-1/2 sm:text-[18px] text-[16px] leading-8 text-justify">
              The community is a source of industry information relating to Data
              Analytics and Artificial Intelligence. It gives you mentorship and
              internship opportunities with [Al] Analytics Intelligence and
              partner companies. 
              <br />
              <br />
              You will have access to a wide range of support from partner
              companies in Data Analysis and Al, as well as webinars, hackathons
              and competitions. You will get to work in close collaboration with
              other members of the community to solve real-world problems in
              real-time.
            </div>
            <div className="md:w-1/2">
              <img
                className="w-full"
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1687423359/AI_COMMUNITY_DAY_114_1_hvf1ml.png"
                alt=""
              />
            </div>
          </div>
          <div className="flex md:flex-row-reverse flex-col md:gap-[80px] gap-[30px] items-center">
            <div className="md:w-1/2 sm:text-[18px] text-[16px] leading-8 text-justify">
              As an IT Professional, you may easily crowdsource your expertise,
              demonstrate your technological prowess, and connect with
              like-minded techies and subject matter experts.
              <br />
              <br />
              Join a vibrant community of learners where you’d learn the
              required technical skills and tools to build your career to help
              you become job ready.
            </div>
            <div className="md:w-1/2">
              <img
                className="w-full"
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1687423360/AI_COMMUNITY_DAY_64_1_ajpn3f.png"
                alt=""
              />
            </div>
          </div>
          <a
            href="https://t.me/+OFUW6YutOewyNzk0"
            target="_blank"
            className="bg-[#5ACBF5] outline-none px-[20px] py-[15px] rounded-[33px] text-black font-[500] relative lg:w-[20%] md:w-[40%] sm:w-[60%]  mx-auto text-center"
            rel="noreferrer"
          >
            Join Community
          </a>
        </div>
      </main>
      <style jsx="true">
        {`
          // .bg-header{
          //   background: url('https://res.cloudinary.com/analytics-intelligence/image/upload/v1686853999/Line_5_1_e9mwkz.png');
          //   background-repeat: no-repeat;
          //   background-size: 100%;
          //   background-position: center;
          // }
          .bg-community {
            background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1687268038/Group_14165_wngei8.png");
            background-repeat: no-repeat;
            background-size: 100%;
            // background-position: center;
          }
        `}
      </style>
    </>
  );
}
