// import Sliders from "./sliders";
import { Helmet, HelmetData } from "react-helmet-async";

const helmetData = new HelmetData({});

export default function Main() {
  return (
    <>
      <Helmet helmetData={helmetData}>
        <title>
          [AI] Analytics Intelligence Africa | Making Analytics and AI
          Accessible To All in Africa
        </title>
        <link
          rel="canonical"
          href="https://africa.analyticsintelligence.com/"
        />
        <meta
          name="description"
          content="The growth of data in today's digital world is fuelling the growth of analytics across the enterprise and the development of artificial intelligence in various sectors. Our goal is to make analytics and artificial intelligence accessible to anyone, across Africa."
        />
        <meta
          name="keywords"
          content="data growth, digital world, analytics, enterprise, artificial intelligence, development, sectors, accessibility, Africa"
        />
      </Helmet>
      <main className="flex flex-col pt-[200px] text-white">
        <div className="bg-header">
          <div className="container w-full flex justify-between pb-[50px]">
            <div className="md:w-[50%] flex flex-col gap-[20px] items-start">
              <h1 className="md:text-[55px] text-[32px] font-[400]">
                Making Analytics and{" "}
                <span className="text-[#5ACBF5]">Artificial Intelligence</span>{" "}
                Accessible to All in Africa
              </h1>
              <p className="sm:text-[18px] text-[16px] leading-8">
                The growth of data in today's digital world is fuelling the
                growth of analytics across the enterprise and the development of
                artificial intelligence in various sectors. Our goal is to make
                analytics and artificial intelligence accessible to anyone,
                across Africa.
              </p>
              <div className="flex items-center gap-[32px]">
                <a href="#product" className=" underline" rel="noreferrer">
                  Learn more
                </a>
              </div>
            </div>
            <div className="w-[50%] md:block hidden">
              <img
                className=""
                src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656707/Group_14106_alveot.png"
                alt="illustration"
              />
            </div>
          </div>
        </div>
        <div className="flex bg flex-col gap-[150px] pt-[50px]" id="product">
          <div className="container flex flex-col gap-[50px] items-center">
            <div className="flex flex-col items-center gap-[20px] text-center md:px-[100px]">
              <h1 className="md:text-[40px] text-[28px] ">
                Delivering <span className="text-[#5ACBF5]">Intelligence</span>{" "}
                As a Service
              </h1>
              <p className="sm:text-[18px] text-[16px] leading-8">
                [AI] Analytics Intelligence has developed solutions that help
                companies harness and structure the value of their data to find
                insight and enable intelligence. We make intelligence available
                on-demand and delivered as a service.
              </p>
            </div>
            {/* <div>
              <img className="sm:max-w-[600px] sm:max-h-[600px] max-h-[500px]" src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1685397094/Group_14107_1_kgn49f.png" alt="" />
            </div> */}
          </div>
          <div className="bg-africa">
            <div className="container flex w-full flex-col gap-[150px]">
              <div className="flex md:flex-row-reverse flex-col md:gap-[5%] gap-[30px] items-center justify-between ">
                <div className="md:w-[50%] flex flex-col gap-[20px] items-start">
                  <h2 className="md:text-[40px] text-[28px] font-[400]">
                    Powerdby<span className="text-[#5ACBF5]">[AI]</span>
                  </h2>
                  <p className="sm:text-[18px] text-[16px] text-justify leading-8">
                    Powerdby[AI] is a low-code machine learning API delivery
                    platform. Built for developers across Africa looking to
                    create intelligent products by embedding machine learning
                    functions.
                  </p>
                  <a
                    href="https://powerdby.ai/"
                    target="_blank"
                    rel="noreferrer"
                    className="bg-[#5ACBF5] rounded-[23px] py-[11px] px-[34px] text-black"
                    title="Powerdby AI"
                  >
                    Learn more
                  </a>
                </div>
                <div className="md:w-[50%]">
                  <img
                    className="max-h-[500px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656707/Group_14107_rpwzo3.png"
                    alt="illustrarion"
                  />
                </div>
              </div>
              <div className="flex md:flex-row flex-col md:gap-[10%] gap-[30px] items-center justify-between">
                <div className="md:w-[50%] flex flex-col gap-[20px] items-start">
                  <h2 className="md:text-[40px] text-[28px] font-[400]">
                    Data <span className="text-[#5ACBF5]">Assistant</span>
                  </h2>
                  <p className="sm:text-[18px] text-[16px] text-justify leading-8">
                    Empower your Digital Marketing teams with AI. Data Assistant
                    is a Conversational AI solution that acts as your personal
                    campaign conversion rate optimization data assistant
                  </p>
                  <a
                    href="https://dataassistant.ai/"
                    target="_blank"
                    rel="noreferrer"
                    className="bg-[#5ACBF5] rounded-[23px] py-[11px] px-[34px] text-black"
                    title="Data Assistant"
                  >
                    Learn more
                  </a>
                </div>
                <div className="md:w-[50%]">
                  <img
                    className="max-h-[500px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656707/Group_14112_ccbg25.png"
                    alt="illustrarion"
                  />
                </div>
              </div>
              <div className="flex md:flex-row-reverse flex-col md:gap-[5%] gap-[30px] items-center justify-between ">
                <div className="md:w-[50%] flex flex-col gap-[20px] items-start">
                  <h2 className="md:text-[40px] text-[28px] font-[400]">
                    <span className="text-[#5ACBF5]">[AI]</span> Academy
                  </h2>
                  <p className="sm:text-[18px] text-[16px] text-justify leading-8">
                    [AI] Academy is the leading online training platform in
                    Africa for data analysis and machine learning. Easy for
                    individuals and organizations to learn the required
                    technical skills for the future of work.
                  </p>
                  <a
                    href="https://aiacademy.africa/"
                    className="bg-[#5ACBF5] rounded-[23px] py-[11px] px-[34px] text-black"
                    target="_blank"
                    rel="noreferrer"
                    title="AI Academy"
                  >
                    Learn more
                  </a>
                </div>
                <div className="md:w-[50%]">
                  <img
                    className="max-h-[500px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656707/Group_14114_qbidth.png"
                    alt="illustrarion"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bgg">
            <div className="container py-[64px] flex flex-col gap-[50px]">
              <div className="flex flex-col items-center gap-[20px] text-center md:px-[100px]">
                <h1 className="md:text-[40px] text-[28px] ">
                  Serving all industries
                </h1>
                <p className="sm:text-[18px] text-[16px] leading-8">
                  Unlock the power of Analytics and Artificial Intelligence for
                  all industries in Africa.
                </p>
              </div>
              <div className="flex flex-wrap gap-[37px] items-center justify-center">
                <div className="lg:w-[31%] md:w-[50%] w-full p-[20px] bg-white rounded-[10px] flex flex-col gap-[18px] min-h-[542px]">
                  <img
                    className="rounded-[10px] h-[250px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1687269353/Group_14164_ga5hpj.png"
                    alt=""
                  />
                  <div className="flex flex-col gap-[10px]">
                    <h1 className="text-[#2F4171] md:text-[32px] text-[25px]">
                      Gaming and casinos
                    </h1>
                    <p className="sm:text-[18px] text-[16px] leading-8 text-[#000000]">
                      Advancing gaming in Africa with Analytics and A for
                      innovation, personalized experiences, and data-driven
                      insights for enhanced gameplay and engagement.{" "}
                    </p>
                  </div>
                </div>
                <div className="lg:w-[31%] md:w-[50%] w-full p-[20px] bg-white rounded-[10px] flex flex-col gap-[18px] min-h-[542px]">
                  <img
                    className="rounded-[10px] h-[250px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656706/doctor-touching-modern-virtual-screen-interface-medical-technology_1_jqpjad.png"
                    alt=""
                  />
                  <div className="flex flex-col gap-[10px]">
                    <h1 className="text-[#2F4171] md:text-[32px] text-[25px]">
                      Healthcare services
                    </h1>
                    <p className="sm:text-[18px] text-[16px] leading-8 text-[#000000]">
                      Transforming healthcare in Africa through advanced
                      analytics and AI solutions for improved diagnostics,
                      personalized treatment, and accessible healthcare
                      management.{" "}
                    </p>
                  </div>
                </div>
                <div className="lg:w-[31%] md:w-[50%] w-full p-[20px] bg-white rounded-[10px] flex flex-col gap-[18px] min-h-[542px]">
                  <img
                    className="rounded-[10px] h-[250px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656706/portrait-african-american-pilot-using-dashboard-command-cabin-takeoff-with-plane-jet-fly-male-aviator-airways-uniform-pushing-control-panel-buttons-power-lever_1_pmbv82.png"
                    alt=""
                  />
                  <div className="flex flex-col gap-[10px]">
                    <h1 className="text-[#2F4171] md:text-[32px] text-[25px]">
                      Aviation
                    </h1>
                    <p className="sm:text-[18px] text-[16px] leading-8 text-[#000000]">
                      Enhancing aviation in Africa with the adoption of our
                      solution in aviation for safety measures, operational
                      efficiency, and passenger experiences.{" "}
                    </p>
                  </div>
                </div>
                <div className="lg:w-[31%] md:w-[50%] w-full p-[20px] bg-white rounded-[10px] flex flex-col gap-[18px] min-h-[542px]">
                  <img
                    className="rounded-[10px] h-[250px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656706/judge-gavel-auction-law-court-concept_1_fhvv7k.png"
                    alt=""
                  />
                  <div className="flex flex-col gap-[10px]">
                    <h1 className="text-[#2F4171] md:text-[32px] text-[25px]">
                      Law enforcement
                    </h1>
                    <p className="sm:text-[18px] text-[16px] leading-8 text-[#000000]">
                      Revolutionizing law enforcement in Africa through
                      analytics and AI for efficient investigations, crime
                      prevention, and safer communities.{" "}
                    </p>
                  </div>
                </div>
                <div className="lg:w-[31%] md:w-[50%] w-full p-[20px] bg-white rounded-[10px] flex flex-col gap-[18px] min-h-[542px]">
                  <img
                    className="rounded-[10px] h-[250px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656706/20230609095311__fpdl_1_cvkwgs.png"
                    alt=""
                  />
                  <div className="flex flex-col gap-[10px]">
                    <h1 className="text-[#2F4171] md:text-[32px] text-[25px]">
                      Retail
                    </h1>
                    <p className="sm:text-[18px] text-[16px] leading-8 text-[#000000]">
                      Our solution will transform the retail landscape in
                      Africa, enabling optimized inventory management,
                      personalized customer experiences, and data-driven
                      decision-making.{" "}
                    </p>
                  </div>
                </div>
                <div className="lg:w-[31%] md:w-[50%] w-full p-[20px] bg-white rounded-[10px] flex flex-col gap-[18px] min-h-[542px]">
                  <img
                    className="rounded-[10px] h-[250px]"
                    src="https://res.cloudinary.com/analytics-intelligence/image/upload/v1686656706/20230609095848__fpdl_1_lbe7nm.png"
                    alt=""
                  />
                  <div className="flex flex-col gap-[10px]">
                    <h1 className="text-[#2F4171] md:text-[32px] text-[25px]">
                      Financial services
                    </h1>
                    <p className="sm:text-[18px] text-[16px] leading-8 text-[#000000]">
                      The goal is that our solution will elevate the financial
                      services in Africa, empowering institutions with advanced
                      data insights, predictive modeling, and risk management.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="container text-center mt-[40px]">
          <h2 className="text-[32px] md:text-[40px] font-[400]">Our Customers</h2>
          <Sliders/>
          </div> */}
          <div className="container pb-[100px]">
            <div className="flex flex-col gap-[20px] items-center">
              <h1 className="md:text-[40px] text-[28px] font-[400]">
                Ready for the next level?
              </h1>
              <p className="sm:text-[18px] text-[16px] text-justify leading-8">
                Join our <span className="text-[#5ACBF5]">2000+</span> community
                of Data Scientists, Data Analysts and Data Enthusiasts
              </p>
              <a
                href="https://t.me/+OFUW6YutOewyNzk0"
                target="_blank"
                className="bg-[#5ACBF5] rounded-[23px] py-[11px] px-[34px] text-black font-[500]"
                rel="noreferrer"
              >
                Join now
              </a>
            </div>
          </div>
        </div>
      </main>
      <style jsx="true">
        {`
          .gif {
            object-fit: cover;
            height: 650px;
            width: 1004px;
          }
          .bgg {
            background: linear-gradient(
                to bottom,
                rgba(90, 203, 245, 0.1),
                rgba(90, 203, 245, 0.1)
              ),
              url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1686660448/Line_2_yjmzxh.svg");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
          }
          .bg-africa {
            background: url("https://res.cloudinary.com/analytics-intelligence/image/upload/v1687267335/Group_14158_rikogl.png");
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
          }
        `}
      </style>
    </>
  );
}
